<template>
    <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script lang="tsx">
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    data() {
        return {
            chartData: {
                labels: ['Daniel', 'Jane', 'John', 'Mike'],
                datasets: [
                    {
                        label: 'Data1',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgb(75, 192, 192)',
                        borderWidth: 1,
                        data: [40, 20, 12, 5]
                    },
                    {
                        label: 'Data2',
                        data: [35, 10, 8, 3],
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgb(75, 192, 192)',
                        borderWidth: 1
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: 'TBD',
                        font: {
                            size: 18
                        },
                        padding: {
                            top: 0,
                            bottom: 10
                        },
                        margin: {
                            top: 0
                        }
                    }
                }
            }
        }
    }
}
</script>