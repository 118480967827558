import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ConfirmDialogTemplate = _resolveComponent("ConfirmDialogTemplate")!
  const _component_ToastMessage = _resolveComponent("ToastMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-container", _ctx.theme])
  }, [
    _createVNode(_component_NavigationBar, {
      theme: _ctx.theme,
      onThemeToggle: _ctx.themeToggle
    }, null, 8, ["theme", "onThemeToggle"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.appState.loading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, { theme: _ctx.theme }, null, 8, ["theme"]),
      _createVNode(_component_ConfirmDialogTemplate),
      _createVNode(_component_ToastMessage)
    ])
  ], 2))
}