<template>
    <div class="dialog-container">
        <div class="title">
            <img :src="icons.info.alert" width="25" height="25" loading="lazy" />
            <span>Confirmation</span>
        </div>
        <div class="inner-container">
            <span>Are you sure you want to proceed?</span>
            <div class="button-container">
                <button type="button" class="btn cancel-button">Cancel</button>
                <button type="button" class="btn ok-button">Ok</button>
            </div>
        </div>

    </div>
</template>

<script lang="tsx">
import { icons } from '@/Utils/Assets';
import { defineComponent } from 'vue';
import { createCookieBanner } from '@/Utils/HelperFunctions';
export default defineComponent({
    data() {
        return {
            icons
        }
    },
    watch: {
        $route() {
            const consentCookie = localStorage.getItem('drp-cookies-consent');
            if (!consentCookie || consentCookie !== 'true') {
                createCookieBanner();
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.dialog-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 30em;
    min-width: 15em;
    opacity: 0;
    border-radius: 10px;
    padding: 1.5em 1em 1.5em 1em;
    pointer-events: none;
    word-wrap: break-word;
    transition: background 0.5s ease-in-out;
}

.dark .dialog-container {
    background: $secondary-dark;
    color: $white;
    box-shadow: 0 2px 8px $primary-purple
}

.light .dialog-container {
    background: $third-dark;
    color: $black;
    box-shadow: 0 2px 8px $light-box-shadow;
}

.inner-container {
    text-align: start;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    font-size: large;
    margin-bottom: 1em;
}

.dialog-container.active {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 0.2s ease, transform 0.2s ease;
    display: block;

}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    gap: 5px
}

.btn {
    background: $primary-purple;
    color: $white;
    font-weight: bold;
    border: none;
    min-width: 5em;
}

.btn:hover {
    background: $secondary-purple;
    color: $white;
}

.cancel-button {
    display: none;
}

.btn-active {
    display: block;
}
</style>
