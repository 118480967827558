import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.data?.title), 1),
    _createElementVNode("img", {
      src: _ctx.img,
      width: "200",
      height: "200",
      style: {"margin-top":"20px"},
      loading: "lazy"
    }, null, 8, _hoisted_1)
  ], 64))
}