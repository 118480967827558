import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "text"
}
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.iconSrc)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass([_ctx.iconClass, 'toolbar-button']),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
        disabled: _ctx.disabled
      }, [
        (_ctx.iconSrc)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.iconSrc,
              width: "24",
              height: "24",
              loading: "lazy"
            }, null, 8, _hoisted_2))
          : (_ctx.text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : (_ctx.text)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass([_ctx.iconClass, 'toolbar-button']),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
          disabled: _ctx.disabled
        }, _toDisplayString(_ctx.text), 11, _hoisted_4))
      : _createCommentVNode("", true)
}