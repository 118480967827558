import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "refinement-card" }
const _hoisted_2 = { class: "refinement-card-header" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "refinement-card-body" }
const _hoisted_6 = { class: "refinement-card-subtitle" }
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "refinement-card-footer" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        title: _ctx.refinementSession?.title,
        class: "refinement-card-title"
      }, _toDisplayString(_ctx.refinementSession?.title), 9, _hoisted_3),
      _createElementVNode("img", {
        class: "closeBtn",
        src: _ctx.icons.refinement.delete,
        width: "30",
        height: "30",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDeleteSession && _ctx.handleDeleteSession(...args))),
        loading: "lazy"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.refinementSession?.type), 1),
      _createElementVNode("p", {
        class: "query-name",
        title: _ctx.refinementSession?.queryName
      }, _toDisplayString(_ctx.refinementSession?.queryName), 9, _hoisted_7)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("img", {
        src: _ctx.refinementSession.restricted ? _ctx.icons.refinement.restricted : _ctx.icons.refinement.unrestricted,
        width: "30",
        height: "30"
      }, null, 8, _hoisted_9),
      _createVNode(_component_GenericButton, {
        text: "Join session",
        onClick: _ctx.handlestartRefinement
      }, null, 8, ["onClick"])
    ])
  ]))
}