<template>
    <Pie id="pie-chart" :options="chartOptions" :data="chartData" :css-classes="cssClasses" :styles="styles" />
</template>

<script lang="tsx">
import { Pie } from 'vue-chartjs';

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'PieChart',
    components: {
        Pie
    },
    props: {
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            chartData: {
                labels: ['PBI\'s', 'Bugs', 'Hotfixes', 'Security'],
                datasets: [
                    {
                        data: [5, 12, 19, 3],
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(204, 0, 51, 0.2)',
                            'rgba(255, 77, 77, 0.2)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235)',
                            'rgba(255, 99, 132)',
                            'rgba(204, 0, 51)',
                            'rgba(255, 77, 77)',
                        ],
                        borderWidth: 1
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'TBD',
                        font: {
                            size: 18
                        },
                        padding: {
                            top: 0,
                            bottom: 10
                        },
                        margin: {
                            top: 0
                        }
                    }
                }
            }
        }
    }
}
</script>