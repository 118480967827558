import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vote-submission-container" }
const _hoisted_2 = { class: "votes" }
const _hoisted_3 = ["onClick", "disabled"]
const _hoisted_4 = { class: "custom-value" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "estimates-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("span", null, [
      _createElementVNode("b", null, "Votes selected: "),
      _createTextVNode("choose one to submit")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueVotes, (vote) => {
        return (_openBlock(), _createElementBlock("div", { key: vote }, [
          _createElementVNode("button", {
            type: "button",
            class: "vote",
            onClick: ($event: any) => (_ctx.submitEstimate(vote)),
            disabled: !_ctx.interactable
          }, _toDisplayString(vote), 9, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("b", null, "Custom Value:", -1)),
      _withDirectives(_createElementVNode("input", {
        class: "input-box",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customValue) = $event)),
        placeholder: "",
        type: "number",
        disabled: !_ctx.interactable
      }, null, 8, _hoisted_5), [
        [_vModelText, _ctx.customValue]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_GenericButton, {
        btnClass: "submit-button",
        text: "Submit",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitEstimate(_ctx.customValue))),
        disabled: !_ctx.interactable
      }, null, 8, ["disabled"]),
      _createVNode(_component_GenericButton, {
        btnClass: "submit-button",
        text: "Re-vote",
        onClick: _ctx.revote,
        disabled: !_ctx.interactable
      }, null, 8, ["onClick", "disabled"])
    ])
  ]))
}