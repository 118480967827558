<template>
    <div class="large-text-container">
        <div class="header-container">
            <img :src="itemIcon" width="15" height="15" loading="lazy" />
            <span>{{ selectedWorkItem?.id }} - {{ selectedWorkItem?.title }}</span>
        </div>
        <div class="big-text-container" v-if="!isBug">
            <h3>Description</h3>
            <TextEditor @dataChange="dataChanged = true" v-model="description"
                placeholder="Click to add a Description" />
        </div>
        <div class="big-text-container" v-if="isBug">
            <h3>Repro Steps</h3>
            <TextEditor @dataChange="dataChanged = true" v-model="reproSteps" placeholder="Click to add Repro Steps" />
        </div>
        <div class="big-text-container" v-if="isBug">
            <h3>System Info</h3>
            <TextEditor @dataChange="dataChanged = true" v-model="systemInfo" placeholder="Click to add System Info" />
        </div>
        <div class="big-text-container">
            <h3>Acceptance Criteria</h3>
            <TextEditor @dataChange="dataChanged = true" v-model="acceptanceCriteria"
                placeholder="Click to add Acceptance Criteria" />
        </div>
        <div class="item-footer">
            <GenericButton btnClass="save-button" text="Save" @click="save" :disabled="!interactable" />
            <img @mouseover="showInfoBox = true" @mouseout="showInfoBox = false" :src="icons.info.info" width="30"
                height="30" />
            <span class="info-box" v-if="showInfoBox">{{ messages.info.imageUploadInfo }}</span>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent, ref, watch } from 'vue';
import DOMPurify from 'dompurify';
import { getIconByType } from '@/Utils/HelperFunctions';
import TextEditor from './TextEditor.vue';
import { updateWorkItem } from '@/Data/Services/WorkItemsService';
import { toast } from '../Dialogs/ToastMessage';
import GenericButton from '@/Components/Buttons/GenericButton.vue';
import { messages } from '@/Utils/Messages';
import { icons } from '@/Utils/Assets';
import { refinement } from '@/Data/Store/refinement';
import { user } from '@/Data/Store/user';

export default defineComponent({
    props: {
        refinementId: {
            type: String,
            default: '',
        },
        selectedWorkItem: {
            type: Object,
            default: {} as any,
        }
    },
    components: {
        TextEditor,
        GenericButton
    },
    data() {
        return {
            dataChanged: false,
            showInfoBox: false,
            icons,
            messages: messages,
            interactable: (refinement.session.data.restricted && refinement.session.users[user.userData?.id].owner) || !refinement.session.data.restricted
        }
    },
    setup(props) {
        const description = ref('');
        const acceptanceCriteria = ref('');
        const reproSteps = ref('');
        const systemInfo = ref('');
        const itemIcon = ref(undefined);
        const isBug = ref(false);

        const sanitizeHtml = (html: string) => {
            //remove any colors from the html so it doesn't not disrupt the theme
            const sanitizedHtmlWithoutInlineColors = html.replace(/style\s*=\s*(['"])(?:(?!\1).)*?(?:\bcolor\s*:\s*[^;'"]*;|background-color\s*:\s*[^;'"]*;)(?:(?!\1).)*?\1/gi, '');
            const sanitizedHtmlWithoutFontColor = sanitizedHtmlWithoutInlineColors.replace(/<font[^>]*?\bcolor\s*=\s*['"][^'"]*['"][^>]*?>(.*?)<\/font>/gi, '$1');
            return DOMPurify.sanitize(sanitizedHtmlWithoutFontColor);
        };

        watch(() => props.selectedWorkItem, (newValue) => {
            description.value = '';
            reproSteps.value = '';
            systemInfo.value = '';
            acceptanceCriteria.value = '';

            if (newValue?.description !== undefined) {
                description.value = sanitizeHtml(newValue.description);
            }

            if (newValue?.reproSteps !== undefined) {
                reproSteps.value = sanitizeHtml(newValue.reproSteps);
            }

            if (newValue?.systemInfo !== undefined) {
                systemInfo.value = sanitizeHtml(newValue.systemInfo);
            }

            if (newValue?.acceptanceCriteria !== undefined) {
                acceptanceCriteria.value = sanitizeHtml(newValue.acceptanceCriteria);
            }

            if (newValue?.type) {
                itemIcon.value = getIconByType(newValue?.type);
                isBug.value = newValue?.type === 'Bug' ? true : false;
            }
        }, { immediate: true });

        return { description, acceptanceCriteria, itemIcon, reproSteps, systemInfo, isBug };
    },
    methods: {
        // Using regular expression to replace emojis with their HTML entity codes
        revertEmojis(str: string) {
            if (str) {
                return str.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, function (emoji) {
                    return '&#' + emoji.codePointAt(0) + ';';
                });
            }
        },
        async save() {
            if ((refinement.session.data.restricted && refinement.session.users[user.userData?.id].owner) || !refinement.session.data.restricted) {
                const fieldsToUpdate = [
                    { current: this.description, selected: this.selectedWorkItem?.description, path: '/fields/System.Description' },
                    { current: this.acceptanceCriteria, selected: this.selectedWorkItem?.acceptanceCriteria, path: '/fields/Microsoft.VSTS.Common.AcceptanceCriteria' },
                    { current: this.reproSteps, selected: this.selectedWorkItem?.reproSteps, path: '/fields/Microsoft.VSTS.TCM.ReproSteps' },
                    { current: this.systemInfo, selected: this.selectedWorkItem?.systemInfo, path: '/fields/Microsoft.VSTS.TCM.SystemInfo' }
                ];

                let operations: Object[] = [];

                for (const field of fieldsToUpdate) {
                    const currentValue = field.current;
                    const selectedValue = field.selected;

                    if (currentValue !== undefined && this.revertEmojis(currentValue) !== this.revertEmojis(selectedValue)) {
                        operations.push({
                            op: 'replace',
                            path: field.path,
                            value: DOMPurify.sanitize(currentValue)
                        });
                    }
                }

                if (operations.length > 0) {
                    const workItemUpdateDTO = {
                        refinementId: this.refinementId,
                        workItemId: this.selectedWorkItem?.id,
                        operation: operations
                    }

                    const updatedWorkItem = await updateWorkItem(workItemUpdateDTO);
                    if (updatedWorkItem) {
                        this.$emit('updateSelectedWorkItem', updatedWorkItem);
                        toast(messages.success.saveWorkItem, 'success');
                    } else {
                        toast(messages.error.saveWorkItem, 'error');
                    }
                }
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.large-text-container {
    display: flex;
    flex-direction: column;
    max-width: 95%;
}

h3 {
    font-weight: bold;
}

.big-text-container {
    padding-bottom: 2em;
}

.light .header-container {
    transition: background 0.5s ease-in-out;
    color: $black;
}

.dark .header-container {
    transition: background 0.5s ease-in-out;
    color: $white;
}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: large;
    gap: 5px;
}

.save-button {
    width: 5em;
    padding: 0.5em;
}

.item-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    margin-bottom: 3em;
}

.info-box {
    color: #ff8800;
}
</style>
