<template>
    <div class="policy-container">
        <h1>Privacy Policy</h1>
        <p>Last updated: 03 June 2024</p>
        <p>This Privacy Policy applies to the DevOps Refinement Portal website and services (referred to as "the
            Portal"). It describes our policies and procedures on the collection, use, and disclosure of your
            information when you use the service and tells you about your privacy rights. By using the service, you
            agree to the collection and use of information in accordance with this Privacy Policy.</p>

        <section>
            <h2>Information Collection and Usage</h2>
            <p>DevOps Refinement Portal prioritises user privacy and collects only strictly necessary data for
                authentication, session management, and API requests purposes. This ensures a secure and efficient user
                experience while maintaining the confidentiality of user information.</p>

            <h3>Data Collected</h3>
            <ul>
                <li>User data obtained through the Microsoft DevOps API sign-in, such as access tokens, user ID, name,
                    and avatar image.</li>
                <li>Organisation and project name selected by users.</li>
                <li>Query IDs selected on refinement session creation.</li>
            </ul>

            <h3>How is this data utilised?</h3>
            <p>All collected data is utilised for the following purposes:</p>
            <ul>
                <li>
                    <p><strong>Authentication:</strong> This is handled by access tokens and user session cookies, which
                        are used as credentials to make any request.</p>
                </li>
                <li>
                    <p><strong>Session Management:</strong> The user cookie is used to handle the session. No sensitive
                        data is stored in the cookie itself, but initial profile information such as user ID and name is
                        required in order to create it.</p>
                </li>
                <li>
                    <p><strong>API Connection:</strong> In addition to the access tokens, requests to the Microsoft API
                        utilise data such as user ID, organisation, project, and query ID to specify the type of request
                        and what data to retrieve.</p>
                </li>
            </ul>

            <h3>Sensitive information</h3>
            <p>All sensitive Microsoft DevOps data collected and stored is securely hashed/encrypted. Upon sign-out, all
                cookies and session data are deleted. Additionally, no sensitive information is stored on the client
                other than the required user name and avatar image.</p>

            <h3>Cookies</h3>
            <p>The Portal uses only strictly necessary cookies for user authentication and session, mandatory to sign in
                and navigate the Portal.</p>
        </section>

        <section>
            <h2>Data Sharing and Usage</h2>
            <p>DevOps Refinement Portal does not engage in any data sharing activities with third parties. Additionally,
                the Portal strictly adheres to the principle of data minimisation, ensuring that only the necessary user
                information is collected and stored for essential operational purposes.</p>

            <p>Key points regarding data sharing and usage:</p>
            <ul>
                <li>
                    <p><strong>No Third-Party Sharing:</strong> The Portal does not share any user data with third-party
                        entities, ensuring that user information remains confidential and secure.</p>
                </li>
                <li>
                    <p><strong>Minimal Data Collection:</strong> The Portal gathers only the minimal amount of user data
                        required for authentication and session management, as outlined in the Information Collection
                        and Usage section.</p>
                </li>
                <li>
                    <p><strong>No Advertising:</strong> The Portal does not display any advertisements or engage in
                        ad-based revenue models.</p>
                </li>
            </ul>
        </section>

        <section>
            <h2>Data Retention and Deletion</h2>
            <p>DevOps Refinement Portal implements deletion policies to ensure user data is managed responsibly and
                securely.</p>

            <p>Key points regarding data retention and deletion:</p>
            <ul>
                <li>
                    <p><strong>Retention of Refinement Session Data:</strong> Refinement session data is retained only
                        for active sessions. However, if a session remains inactive (no user activity) for the period of
                        one month, the system automatically deletes the session.</p>
                </li>
                <li>
                    <p><strong>User-Requested Deletion:</strong> Users have the right to request the deletion of their
                        account and associated data. They can initiate this process by sending an email to <a
                            href="mailto:refinementportal@gmail.com">refinementportal@gmail.com</a> to promptly address
                        their request in
                        accordance with applicable data protection regulations.</p>
                </li>
                <li>
                    <p><strong>Refinement Live Session Data:</strong> During refinement live sessions, the Portal stores
                        user names and refinement data to facilitate real-time collaboration. This data is transient and
                        is deleted once the session ends. Additionally, any user-specific data associated with a live
                        session is removed when the user leaves the session.</p>
                </li>
            </ul>
        </section>

        <section>
            <h2>Updates</h2>
            <p>All updates are displayed in the News section of the Portal. Users are encouraged to check this section
                frequently to stay up to date on the latest enhancements and security measures.</p>

            <h3>Privacy Policy</h3>
            <p>This Privacy Policy may be updated to reflect any changes in the Portal's data handling and security
                practices.</p>

            <h3>Security</h3>
            <p>As part of the Portal security strategy, Common Vulnerabilities and Exposures (CVEs) are actively
                monitored to promptly address any potential threats. This vulnerability management process includes
                regular software updates and patch management to mitigate risks effectively. Furthermore, all libraries
                and software components used are carefully selected and vetted, ensuring they meet stringent security
                standards and do not compromise the security posture.</p>
            <p>Any new CVEs will be promptly addressed if a fix is already available. In cases where there is not, it
                will be monitored, assessed, and possibly replaced entirely. Additionally, weekly security checks are
                conducted to identify and resolve any emerging vulnerabilities, ensuring the system remains secure and
                up-to-date.</p>

            <h3>Features and Patches</h3>
            <p>Security patches, including those addressing CVEs, as well as new features, bug fixes, and minor
                improvements, are part of the release information updates.</p>
        </section>

        <footer>
            <br>
            <p>For any questions or concerns regarding this Privacy Policy, please refer to <a href="#"
                    @click="redirect">Support</a>.</p>
        </footer>
    </div>
</template>

<script lang="tsx">
import { routes } from '@/Router/routes';
import { defineComponent } from 'vue';


export default defineComponent({
    methods: {
        redirect(event: Event) {
            event.preventDefault();
            this.$router.push(routes.support)
        }
    }
})

</script>

<style lang="scss" scoped>
.policy-container {
    padding: 5em 1em 1em 1em;
}
</style>