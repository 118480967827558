import { icons } from "@/Utils/Assets";

export const toast = async (message: string, type: string) => {
    const toastBox = document.querySelector('.toast-container');
    const toastMessage = document.querySelector('.toast-message');
    const toastIcon = document.querySelector('.toast-icon');

    if (toastBox && toastMessage && toastIcon) {
        toastMessage.innerHTML = message;

        // Load the icon based on type
        let iconSrc = '';
        switch (type) {
            case 'info':
                iconSrc = icons.info.alert;
                break;
            case 'success':
                iconSrc = icons.info.success;
                break;
            case 'error':
                iconSrc = icons.info.error;
                break;
            default:
                iconSrc = icons.info.alert;
                break;
        }

        // Load the icon asynchronously and wait until it's loaded
        await new Promise<void>((resolve) => {
            const icon = new Image();
            icon.onload = () => resolve();
            icon.src = iconSrc;
        });

        // Set the icon source after it's fully loaded
        toastIcon.setAttribute('src', iconSrc);

        // Show the toast
        toastBox.classList.add('active');

        // Hide the toast after 2 seconds
        setTimeout(() => {
            toastBox.classList.remove('active');
        }, 2000);
    }
}
