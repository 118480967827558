import { createRouter, createWebHistory } from 'vue-router';
import { signedInStatus } from '../Data/Services/AuthService';
import LandingPage from '../Pages/LandingPage.vue';
import DashboardPage from '../Pages/DashboardPage.vue';
import RefinementPage from '../Pages/RefinementPage.vue';
import RefinementSession from '../Pages/RefinementSession.vue';
import AuthPage from '../Pages/AuthPage.vue';
import ProfilePage from '../Pages/ProfilePage.vue';
import { routes } from './routes';
import PrivacyPolicy from '../Pages/PrivacyPolicy.vue';
import NewsPage from '../Pages/NewsPage.vue';
import SupportPage from '@/Pages/SupportPage.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: "Landing",
			path: routes.landing,
			component: LandingPage,
			meta: { requiresAuth: false }
		},
		{
			name: "Dashboard",
			path: routes.dashboard,
			component: DashboardPage,
			meta: { requiresAuth: true }
		},
		{
			name: "Refinement",
			path: routes.refinement,
			component: RefinementPage,
			meta: { requiresAuth: true }
		},
		{
			name: "RefinementSession",
			path: routes.refinementSession,
			component: RefinementSession,
			meta: { requiresAuth: true }
		},
		{
			name: "Auth",
			path: routes.auth,
			component: AuthPage,
			meta: { requiresAuth: false }
		},
		{
			name: "Profile",
			path: routes.profile,
			component: ProfilePage,
			meta: { requiresAuth: true }
		},
		{
			name: "PrivacyPolicy",
			path: routes.privacyPolicy,
			component: PrivacyPolicy,
			meta: { requiresAuth: true }
		},
		{
			name: "News",
			path: routes.news,
			component: NewsPage,
			meta: { requiresAuth: true }
		},
		{
			name: "Support",
			path: routes.support,
			component: SupportPage,
			meta: { requiresAuth: true }
		},
	],
})

router.beforeEach(async (to, from, next) => {
	const dialogBox = document.querySelector('.dialog-container');
	if (dialogBox && dialogBox.classList.contains('active')) {
		dialogBox.classList.remove('active');
	}

	if (to.meta.requiresAuth) {
		const isSignedIn = await signedInStatus();
		if (isSignedIn || to.path === routes.privacyPolicy || to.path === routes.support
			|| to.path === routes.news) {
			next();
		} else {
			next(routes.landing);
		}
	} else if (to.path === routes.landing && await signedInStatus()) {
		next(routes.dashboard);
	} else {
		next();
	}
})


export default router;