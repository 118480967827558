import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.icons.info.alert,
        width: "25",
        height: "25",
        loading: "lazy"
      }, null, 8, _hoisted_3),
      _cache[0] || (_cache[0] = _createElementVNode("span", null, "Confirmation", -1))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "inner-container" }, [
      _createElementVNode("span", null, "Are you sure you want to proceed?"),
      _createElementVNode("div", { class: "button-container" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn cancel-button"
        }, "Cancel"),
        _createElementVNode("button", {
          type: "button",
          class: "btn ok-button"
        }, "Ok")
      ])
    ], -1))
  ]))
}