<template>
    <footer class="footer">
        <div class="footer-content">
            All rights reserved. Content & design by DevOps Refinement Portal, 2024.
            <div class="links-container">
                <a href="#" @click="redirect($event, routes.privacyPolicy)">Privacy Policy</a>
                &middot;
                <a href="#" @click="redirect($event, routes.support)">Support</a>
            </div>
        </div>
    </footer>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { routes } from '@/Router/routes';

export default defineComponent({
    methods: {
        redirect(event: Event, route: string) {
            event.preventDefault();
            this.$router.push(route)
        }
    },
    data() {
        return {
            routes: routes
        }
    }
})
</script>

<style lang="scss" scoped>
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    font-size: 14px;
}

.dark .footer {
    transition: background 0.5s ease-in-out;
    background-color: $primary-dark;
    color: $white;

    a {
        color: $white;
    }
}

.light .footer {
    transition: background 0.5s ease-in-out;
    background-color: $primary-light;
    color: $black;

    a {
        color: $black;
    }
}

.footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.links-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

@media (max-width: 600px) {
    .footer-content {
        display: flex;
        flex-direction: column;
        text-align: left;
    }
}
</style>