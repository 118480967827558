<template>
    <div class="dashboard-container">
        <div class="title-container">
            <h1>Welcome, {{ user.userData?.name }}</h1>
        </div>
        <div class="main-charts-container">
            <div class="chart-column">
                <PieChart />
            </div>
            <div class="chart-column">
                <BarChart />
            </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; margin-top: 2em;">
            <img :src="images.pepe" width="200" height="200" style="padding: 20px;" loading="lazy" />
            <h5>I'm working on it!</h5>
            <GenericButton @click="reRoute" text="Go to Refinement" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { user } from '../Data/Store/user';
import BarChart from '../Components/Charts/BarChart.vue';
import PieChart from '../Components/Charts/PieChart.vue'
import GenericButton from '@/Components/Buttons/GenericButton.vue';
import { routes } from '@/Router/routes';
import { images } from '@/Utils/Assets';

export default defineComponent({
    data() {
        return {
            user,
            images
        }
    },
    components: {
        BarChart,
        PieChart,
        GenericButton
    },
    methods: {
        reRoute() {
            this.$router.push(routes.refinement);
        }
    }
})
</script>

<style lang="scss" scoped>
.dashboard-container {
    padding: 5em 1em 1em 1em;
    width: 100%;
    height: auto;
}

.title-container {
    margin: 0 0 2em 1em;
}

.main-charts-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1em;
}

.chart-column {
    flex-grow: 1;
    border-radius: 5px;
    padding: 1em;
    overflow: hidden;
    max-width: 50%;
    min-height: 450px;
}

.dark .chart-column {
    transition: background 0.5s ease-in-out;
    background: $secondary-dark;
    box-shadow: 0 2px 8px $primary-purple;
}

.light .chart-column {
    transition: background 0.5s ease-in-out;
    background: $third-light;
    box-shadow: 0 2px 8px $light-box-shadow;
}

@media (max-width: 990px) {
    .main-charts-container {
        flex-direction: column;
    }

    .chart-column {
        max-width: 100%;
    }
}
</style>
