<template>
    <div class="session-container">
        <h3>Select work items to refine</h3>
        <div class="left-container">
            <WorkItem class="test" v-for="(item, index) in workItems" :key="index" :item="item"
                v-on:selectedItem="selectedItem(index)" />
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import WorkItem from './WorkItem.vue';
import { user } from '@/Data/Store/user';

export default defineComponent({
    components: {
        WorkItem
    },
    data() {
        return {
            user,
            workItems: [
                {
                    id: 1,
                    type: 'Bug',
                    name: "Work item 1",
                    state: "New",
                    estimate: 2,
                    description: "This is the first item",
                    acceptancCriteria: "- Do this. - Do that"
                },
                {
                    id: 2,
                    type: 'PBI',
                    name: "Work item 2",
                    state: "Testing",
                    estimate: 1,
                    description: "This is the second item",
                    acceptancCriteria: "- Do this. - Do that"
                },
                {
                    id: 3,
                    type: 'Scrum',
                    name: "Work item 3",
                    state: "Development",
                    estimate: 5,
                    description: "This is the third item",
                    acceptancCriteria: "- Do this. - Do that"
                }
            ] as any,
            selectedWorkItem: -1,
        }
    },
    methods: {
        selectedItem(index: any) {
            this.selectedWorkItem = index;
        }
    }
})
</script>

<style lang="scss" scoped>
.session-container {
    display: flex;
    flex-direction: column;
    padding: 7em 2em 2em 2em;
}

.left-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 24em;
}

h3 {
    font-weight: bold;
}

@media screen and (max-width: 990px) {
    .session-container {
        display: flex;
        flex-direction: column;
    }

    .left-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 100%
    }
}
</style>
