import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "session-container" }
const _hoisted_2 = { class: "left-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkItem = _resolveComponent("WorkItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Select work items to refine", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workItems, (item, index) => {
        return (_openBlock(), _createBlock(_component_WorkItem, {
          class: "test",
          key: index,
          item: item,
          onSelectedItem: ($event: any) => (_ctx.selectedItem(index))
        }, null, 8, ["item", "onSelectedItem"]))
      }), 128))
    ])
  ]))
}