import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "toolbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_content = _resolveComponent("editor-content")!
  const _component_ToolbarButton = _resolveComponent("ToolbarButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.editor)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_editor_content, { editor: _ctx.editor }, null, 8, ["editor"]),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.bold,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editor.chain().focus().toggleBold().run())),
              disabled: !_ctx.editor.can().chain().focus().toggleBold().run() || !_ctx.editable,
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('bold') })
            }, null, 8, ["iconSrc", "disabled", "class"])
          ])), [
            [
              _directive_tooltip,
              'Bold',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.italic,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editor.chain().focus().toggleItalic().run())),
              disabled: !_ctx.editor.can().chain().focus().toggleItalic().run() || !_ctx.editable,
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('italic') })
            }, null, 8, ["iconSrc", "disabled", "class"])
          ])), [
            [
              _directive_tooltip,
              'Italic',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.strike,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editor.chain().focus().toggleStrike().run())),
              disabled: !_ctx.editor.can().chain().focus().toggleStrike().run() || !_ctx.editable,
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('strike') })
            }, null, 8, ["iconSrc", "disabled", "class"])
          ])), [
            [
              _directive_tooltip,
              'Strike',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.codeBlock,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editor.chain().focus().toggleCode().run())),
              disabled: !_ctx.editor.can().chain().focus().toggleCode().run() || !_ctx.editable,
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('code') })
            }, null, 8, ["iconSrc", "disabled", "class"])
          ])), [
            [
              _directive_tooltip,
              'Code block',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.bulletList,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editor.chain().focus().toggleBulletList().run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('bulletList') }),
              disabled: !_ctx.editable
            }, null, 8, ["iconSrc", "class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Bullet list',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.orderedList,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.editor.chain().focus().toggleOrderedList().run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('orderedList') }),
              disabled: !_ctx.editable
            }, null, 8, ["iconSrc", "class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Ordered list',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              text: "H1",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 1 }).run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('heading', { level: 1 }) }),
              disabled: !_ctx.editable
            }, null, 8, ["class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Heading 1',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              text: "H2",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 2 }).run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('heading', { level: 2 }) }),
              disabled: !_ctx.editable
            }, null, 8, ["class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Heading 2',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              text: "H3",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 3 }).run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('heading', { level: 3 }) }),
              disabled: !_ctx.editable
            }, null, 8, ["class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Heading 3',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              text: "H4",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 4 }).run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('heading', { level: 4 }) }),
              disabled: !_ctx.editable
            }, null, 8, ["class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Heading 4',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              text: "H5",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 5 }).run())),
              class: _normalizeClass({ 'is-active': _ctx.editor.isActive('heading', { level: 5 }) }),
              disabled: !_ctx.editable
            }, null, 8, ["class", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Heading 5',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createElementVNode("input", {
              type: "file",
              ref: "fileInput",
              style: {"display":"none"},
              onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.uploadImageFromLocal && _ctx.uploadImageFromLocal(...args)))
            }, null, 544),
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.fileUpload,
              onClick: _cache[12] || (_cache[12] = ($event: any) => ((_ctx.$refs as any).fileInput.click())),
              disabled: !_ctx.editable
            }, null, 8, ["iconSrc", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Upload image',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_ToolbarButton, {
              iconSrc: _ctx.icons.textEditor.urlUpload,
              onClick: _ctx.addImage,
              disabled: !_ctx.editable
            }, null, 8, ["iconSrc", "onClick", "disabled"])
          ])), [
            [
              _directive_tooltip,
              'Embed url image',
              void 0,
              { top: true }
            ]
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}