import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "release-details"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.content)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.content.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.content.description), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.content.features)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("h3", null, "What's New:", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.features, (feature, index) => {
                return (_openBlock(), _createElementBlock("ul", { key: index }, [
                  _createElementVNode("li", null, _toDisplayString(feature), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.content.changes)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Changes:", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.changes, (change, index) => {
                return (_openBlock(), _createElementBlock("ul", { key: index }, [
                  _createElementVNode("li", null, _toDisplayString(change), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.content.issues)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Known Issues:", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content.issues, (issue, index) => {
                return (_openBlock(), _createElementBlock("ul", { key: index }, [
                  _createElementVNode("li", null, _toDisplayString(issue), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}