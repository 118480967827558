import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "refinement-container" }
const _hoisted_2 = { class: "active-sessions-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RefinementModal = _resolveComponent("RefinementModal")!
  const _component_RefinementCard = _resolveComponent("RefinementCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RefinementModal, {
      onUpdateRefinementSessions: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRefinementSessions()))
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refinementSessions, (session) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "cards-container",
          key: session.id
        }, [
          _createVNode(_component_RefinementCard, {
            refinementSession: session,
            onStartRefinement: ($event: any) => (_ctx.startRefinementSession(session)),
            onDeleteSession: ($event: any) => (_ctx.deleteSession(session))
          }, null, 8, ["refinementSession", "onStartRefinement", "onDeleteSession"])
        ]))
      }), 128))
    ])
  ]))
}