<template>
    <div class="support-container">
        <h1>Support</h1>
        <p>Welcome to the Support page. We are here to help you with any questions, concerns, or feedback you might
            have.</p>

        <h2 id="contact-form">Contact Us</h2>
        <p>If you have specific questions, need personalised assistance, or just want to
            provide feedback and suggestions, please reach out to us through our contact
            email at <a href="mailto:refinementportal@gmail.com">refinementportal@gmail.com</a>.
        </p>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
export default defineComponent({})
</script>

<style lang="scss" scoped>
.support-container {
    padding: 5em 1em 1em 1em;
}
</style>