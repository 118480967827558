import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "support-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "Support", -1),
    _createElementVNode("p", null, "Welcome to the Support page. We are here to help you with any questions, concerns, or feedback you might have.", -1),
    _createElementVNode("h2", { id: "contact-form" }, "Contact Us", -1),
    _createElementVNode("p", null, [
      _createTextVNode("If you have specific questions, need personalised assistance, or just want to provide feedback and suggestions, please reach out to us through our contact email at "),
      _createElementVNode("a", { href: "mailto:refinementportal@gmail.com" }, "refinementportal@gmail.com"),
      _createTextVNode(". ")
    ], -1)
  ])))
}