import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-container" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "main-charts-container" }
const _hoisted_4 = { class: "chart-column" }
const _hoisted_5 = { class: "chart-column" }
const _hoisted_6 = { style: {"display":"flex","flex-direction":"column","align-items":"center","margin-top":"2em"} }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, "Welcome, " + _toDisplayString(_ctx.user.userData?.name), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PieChart)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BarChart)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", {
        src: _ctx.images.pepe,
        width: "200",
        height: "200",
        style: {"padding":"20px"},
        loading: "lazy"
      }, null, 8, _hoisted_7),
      _cache[0] || (_cache[0] = _createElementVNode("h5", null, "I'm working on it!", -1)),
      _createVNode(_component_GenericButton, {
        onClick: _ctx.reRoute,
        text: "Go to Refinement"
      }, null, 8, ["onClick"])
    ])
  ]))
}