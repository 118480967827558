import { createApp } from 'vue'
import App from './App.vue'
import router from './Router/router'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Tooltip } from './Directives/TooltipDirective';
import './Directives/TooltipDirective.scss';

const app = createApp(App)
app.use(router)
app.directive('tooltip', Tooltip);
app.mount('#app')
