import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-dark fixed-top" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "navbar-toggler",
  type: "button",
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#navbarOffcanvas",
  "aria-controls": "navbarOffcanvas",
  "aria-expanded": "false",
  "aria-label": "Toggle navigation"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "offcanvas offcanvas-end",
  tabindex: "-1",
  id: "navbarOffcanvas",
  "aria-labelledby": "navbarOffcanvasLabel"
}
const _hoisted_7 = { class: "offcanvas-header" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "offcanvas-body" }
const _hoisted_10 = {
  key: 0,
  class: "navbar-nav ms-auto"
}
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { class: "nav-item dropdown" }
const _hoisted_14 = {
  class: "nav-link inline-link",
  role: "button",
  id: "userInfoDropdown",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "userInfoDropdown"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "nav-item dropdown" }
const _hoisted_21 = {
  class: "nav-link inline-link",
  role: "button",
  id: "userSettingsDropdown",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_22 = ["src"]
const _hoisted_23 = {
  class: "dropdown-menu dropdown-menu-end",
  "aria-labelledby": "userSettingsDropdown"
}
const _hoisted_24 = ["src"]
const _hoisted_25 = ["src"]
const _hoisted_26 = ["src"]
const _hoisted_27 = {
  key: 1,
  class: "navbar-nav ms-auto"
}
const _hoisted_28 = {
  key: 0,
  class: "nav-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand",
        to: "/"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.icons.devops.logo,
            alt: "Logo",
            style: {"margin-right":"10px"},
            width: "30",
            height: "30"
          }, null, 8, _hoisted_3),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "drp-title" }, "DevOps Refinement Portal", -1))
        ]),
        _: 1
      }),
      _createElementVNode("button", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.icons.general.menu,
          alt: "Off canvas header button",
          width: "30",
          height: "30",
          loading: "lazy"
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            class: "closeBtn",
            src: _ctx.icons.general.close,
            "data-bs-dismiss": "offcanvas",
            "aria-label": "Close",
            width: "30",
            height: "30",
            loading: "lazy"
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.user.userData?.id && _ctx.user.userData?.name)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                _createElementVNode("li", _hoisted_11, [
                  _createVNode(_component_router_link, {
                    class: "nav-link inline-link",
                    to: "/dashboard"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Dashboard")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_12, [
                  _createVNode(_component_router_link, {
                    class: "nav-link inline-link",
                    to: "/refinement"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Refinement")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("img", {
                      src: _ctx.theme === 'dark' ? _ctx.icons.general.informationWhite : _ctx.icons.general.informationBlack,
                      alt: "information Icon",
                      width: "25",
                      height: "25",
                      loading: "lazy"
                    }, null, 8, _hoisted_15)
                  ]),
                  _createElementVNode("ul", _hoisted_16, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, {
                        class: "dropdown-item dropdown-icon",
                        to: "/news"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: _ctx.theme === 'dark' ? _ctx.icons.general.newsWhite : _ctx.icons.general.newsBlack,
                            alt: "Policy Icon",
                            width: "20",
                            height: "20",
                            loading: "lazy"
                          }, null, 8, _hoisted_17),
                          _cache[6] || (_cache[6] = _createElementVNode("span", null, "News", -1))
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, {
                        class: "dropdown-item dropdown-icon",
                        to: "/privacy"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: _ctx.theme === 'dark' ? _ctx.icons.general.policyWhite : _ctx.icons.general.policyBlack,
                            alt: "Policy Icon",
                            width: "20",
                            height: "20",
                            loading: "lazy"
                          }, null, 8, _hoisted_18),
                          _cache[7] || (_cache[7] = _createElementVNode("span", null, "Privacy Policy", -1))
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, {
                        class: "dropdown-item dropdown-icon",
                        to: "/support"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: _ctx.theme === 'dark' ? _ctx.icons.general.supportWhite : _ctx.icons.general.supportBlack,
                            alt: "Support Icon",
                            width: "20",
                            height: "20",
                            loading: "lazy"
                          }, null, 8, _hoisted_19),
                          _cache[8] || (_cache[8] = _createElementVNode("span", null, "Support", -1))
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _createElementVNode("li", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("img", {
                      class: "avatar-img",
                      src: _ctx.user.avatar ? `data:image/png;base64,${_ctx.user.avatar}` : _ctx.icons.general.defaultUserAvatar,
                      alt: "Profile",
                      width: "30",
                      height: "30",
                      loading: "lazy"
                    }, null, 8, _hoisted_22)
                  ]),
                  _createElementVNode("ul", _hoisted_23, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, {
                        class: "dropdown-item dropdown-icon",
                        to: "/profile"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: _ctx.theme === 'dark' ? _ctx.icons.general.settingsWhite : _ctx.icons.general.settingsBlack,
                            alt: "Settings Icon",
                            width: "20",
                            height: "20",
                            loading: "lazy"
                          }, null, 8, _hoisted_24),
                          _createElementVNode("span", null, _toDisplayString(_ctx.userName), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("div", {
                        class: "dropdown-item dropdown-icon",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('themeToggle')))
                      }, [
                        _createElementVNode("img", {
                          src: _ctx.theme === 'dark' ? _ctx.icons.theme.sun : _ctx.icons.theme.moon,
                          alt: "Theme Icon",
                          width: "20",
                          height: "20",
                          loading: "lazy"
                        }, null, 8, _hoisted_25),
                        _createElementVNode("span", null, _toDisplayString(_ctx.theme === 'dark' ? 'Light' : 'Dark'), 1)
                      ])
                    ]),
                    _cache[10] || (_cache[10] = _createElementVNode("li", null, [
                      _createElementVNode("hr", { class: "dropdown-divider" })
                    ], -1)),
                    _createElementVNode("li", null, [
                      _createElementVNode("div", {
                        class: "dropdown-item dropdown-icon",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.signOut()))
                      }, [
                        _createElementVNode("img", {
                          src: _ctx.theme === 'dark' ? _ctx.icons.general.leaveWhite : _ctx.icons.general.leaveBlack,
                          alt: "Theme Icon",
                          width: "20",
                          height: "20",
                          loading: "lazy"
                        }, null, 8, _hoisted_26),
                        _cache[9] || (_cache[9] = _createElementVNode("span", null, "Sign Out", -1))
                      ])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_27, [
                (!_ctx.appState.loading)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_28, [
                      _createVNode(_component_GenericButton, {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.authorize())),
                        text: "Sign In"
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ])
    ])
  ]))
}