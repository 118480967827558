<template>
    <div class="features-container">
        <div class="card-item">
            <img :src="images.landing.estimates" width="110" height="110" loading="lazy">
            <div>
                <h5 class="card-title">Estimates</h5>
                <p class="card-text">Conduct refinement sessions where team members collaboratively evaluate and
                    estimate the complexity of sprint work items through a consensus-based voting process.
                </p>
            </div>
        </div>
        <div class="card-item">
            <img :src="images.landing.refinement" width="110" height="110" loading="lazy">
            <div>
                <h5 class="card-title">Sprint Refinement</h5>
                <p class="card-text">Facilitate refinement sessions to review tagged work items, engage in
                    visualisation, discussion, and collective voting.
                    Upon completion of the estimation and voting process,
                    seamlessly submit the complexity scores of work items directly to your Azure DevOps project.</p>
            </div>
        </div>
        <div class="card-item">
            <img :src="images.landing.dashboard" width="110" height="110" loading="lazy">
            <div>
                <h5 class="card-title">Dashboard</h5>
                <p class="card-text">
                    Access a dashboard featuring various refinement statistics.
                    Easily monitor different refinement outcomes, analyse work item data, and gain valuable insights of
                    your project.
                </p>
            </div>
        </div>
    </div>
    <div v-if="update?.content" class="features-container news-container">
        <div class="card-item">
            <div>
                <h2>News and updates</h2>
                <NewsContent :content="update?.content" />
                <div class="news-footer">
                    <GenericButton text="All news and updates" @click="newsRedirect" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="tsx">
import { images } from '@/Utils/Assets';
import { defineComponent } from 'vue'
import NewsContent from '../FormattedText/NewsContent.vue';
import { getNewsAndUpdates } from '@/Utils/HelperFunctions';
import GenericButton from '../Buttons/GenericButton.vue';
import { routes } from '@/Router/routes';
export default defineComponent({
    data() {
        return {
            images,
            update: [] as any
        }
    },
    components: {
        NewsContent,
        GenericButton
    },
    async mounted() {
        this.update = await getNewsAndUpdates(true);
    },
    methods: {
        newsRedirect() {
            this.$router.push(routes.news);
        }
    }
})
</script>

<style lang="scss" scoped>
.features-container {
    display: flex;
    flex-direction: row;
    gap: 2em;
}

.card-item {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    width: 100%;
    padding: 1em;
    gap: 1.5em;
    border-radius: 5px;
}

.dark .card-item {
    background: $primary-dark;
    transition: background 0.5s ease-in-out;
    box-shadow: 0 2px 8px $primary-purple;
}

.light .card-item {
    background: $primary-light;
    box-shadow: 0 2px 8px $light-box-shadow;
    transition: background 0.5s ease-in-out;
}

p {
    color: $info-text;
}

.news-container {
    margin-top: 5em;
}

.news-footer {
    margin-top: 2em;
}

@media screen and (max-width: 990px) {
    .features-container {
        display: flex;
        flex-direction: column;
        padding-right: 1.5em;
    }

    .news-container {
        margin-top: 2em;
    }

}
</style>
