<template>
    <div class="spinner d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';

export default defineComponent({
})
</script>

<style lang="scss" scoped>
.spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.dark .spinner {
    background: $primary-dark;

}

.light .spinner {
    background: $primary-light;
}
</style>