import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pie = _resolveComponent("Pie")!

  return (_openBlock(), _createBlock(_component_Pie, {
    id: "pie-chart",
    options: $data.chartOptions,
    data: $data.chartData,
    "css-classes": $props.cssClasses,
    styles: $props.styles
  }, null, 8, ["options", "data", "css-classes", "styles"]))
}