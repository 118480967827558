import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  key: 0,
  class: "chevron"
}
const _hoisted_3 = {
  key: 0,
  class: "dropdown-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_node = _resolveComponent("tree-node", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectItem(_ctx.selectedItem))),
      class: "form-select form-select-md",
      "aria-label": ".form-select-md"
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("option", { value: '' }, "Select a query...", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topLevelItems, (item) => {
        return (_openBlock(), _createElementBlock("option", {
          key: item.id,
          value: item.id
        }, [
          (item.children && item.children.length > 0 && _ctx.selectedItem !== item.id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "►"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.name), 1)
        ], 8, _hoisted_1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.selectedItem]
    ]),
    (_ctx.selectedItem && _ctx.getChildItems(_ctx.selectedItem).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_component_tree_node, {
            treeData: _ctx.getChildItems(_ctx.selectedItem),
            key: _ctx.selectedItem,
            onItemSelected: _ctx.selectItem
          }, null, 8, ["treeData", "onItemSelected"]))
        ]))
      : _createCommentVNode("", true)
  ]))
}