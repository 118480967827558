<template>
    <div class="news-container">
        <h1>News and Updates</h1>
        <div class="release" v-for="(update, index) in updates" :key="index">
            <div class="divider"></div>
            <h2>{{ update.title }}</h2>
            <p class="release-date">{{ update.date }}</p>
            <p class="release-version">{{ update.version }}</p>
            <NewsContent :content="update.content" />
        </div>
    </div>
</template>

<script lang="tsx">
import NewsContent from '@/Components/FormattedText/NewsContent.vue';
import { getNewsAndUpdates } from '@/Utils/HelperFunctions';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        NewsContent
    },
    data() {
        return {
            updates: [] as any
        }
    },
    async mounted() {
        this.updates = await getNewsAndUpdates(false);
        this.updates.reverse();
    },
})

</script>

<style lang="scss" scoped>
.news-container {
    padding: 5em 1em 1em 1em;
    width: 100%;
    height: auto;
}

.release {
    margin: 40px 0 40px 0;
}

.release h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.release-date,
.release-version {
    font-size: 14px;
    color: $info-text;
}

.release-details h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
}

.release-details ul {
    margin-left: 20px;
}

.release-details li {
    margin-bottom: 10px;
}

.divider {
    border-top: 1px $info-text solid;
    margin-bottom: 1em;
}
</style>