import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toast-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "title" }, [
      _createElementVNode("img", {
        class: "toast-icon",
        width: "25",
        height: "25"
      }),
      _createElementVNode("div", { class: "toast-message" })
    ], -1)
  ])))
}