<template>
    <div>
        <select v-model="selectedItem" @change="selectItem(selectedItem)" class="form-select form-select-md"
            aria-label=".form-select-md">
            <option :value="''">Select a query...</option>
            <option v-for="item in topLevelItems" :key="item.id" :value="item.id">
                <span v-if="item.children && item.children.length > 0 && selectedItem !== item.id"
                    class="chevron">&#9658;</span>
                {{ item.name }}
            </option>
        </select>

        <div v-if="selectedItem && getChildItems(selectedItem).length > 0" class="dropdown-container">
            <tree-node :treeData="getChildItems(selectedItem)" :key="selectedItem"
                @itemSelected="selectItem"></tree-node>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TreeNode',
    props: ['treeData'],
    data() {
        return {
            selectedItem: '',
            topLevelItems: [] as any,
            loadedItems: {} as any
        };
    },
    mounted() {
        this.topLevelItems = this.treeData;
        this.topLevelItems.sort((a: any, b: any) => {
            if (a.children && a.children.length > 0) {
                return -1;
            } else if (b.children && b.children.length > 0) {
                return 1;
            } else {
                return 0;
            }
        });
    },
    methods: {
        getChildItems(itemId: string) {
            if (!this.loadedItems[itemId]) {
                const selectedItem = this.findItemById(this.treeData, itemId);
                if (selectedItem) {
                    this.loadedItems[itemId] = selectedItem.children || [];
                }
            }
            return this.loadedItems[itemId] || [];
        },
        findItemById(tree: any, id: string): any {
            for (let item of tree) {
                if (item.id === id) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const foundItem = this.findItemById(item.children, id);
                    if (foundItem) {
                        return foundItem;
                    }
                }
            }
            return null;
        },
        selectItem(itemId: string) {
            const selectedItem = this.findItemById(this.treeData, itemId);
            if (selectedItem && (!selectedItem.children || selectedItem.children.length === 0)) {
                this.$emit('itemSelected', itemId, selectedItem.name);
            }
            this.loadedItems = {};
        }
    }
})
</script>

<style scoped>
.chevron {
    margin-right: 5px;
}

.dropdown-container {
    margin-top: 5px;
}
</style>
