/* eslint-disable */
import router from '../../Router/router';
import { user } from '@/Data/Store/user';
import { appState, resetAppStore } from '../Store/appState';
import { resetUserStore } from '../Store/user';
import { fullReset, microsoftSignOutReset } from '../../Utils/HelperFunctions';
import { routes } from '@/Router/routes';
import { URL } from '../Configs/Urls';
import { statusCode } from '@/Utils/StatusCodes';
import { getProfileAvatar } from './DevopsService';

export const authorize = () => {
    window.location.href = URL.auth.authorize;
}

export const signIn = async () => {
    appState.loading = true;
    await fetch(URL.auth.signIn, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'params': location.search }),
        credentials: 'include'
    })
        .then(async (resp) => {
            if (resp.status === statusCode.OK) {
                const data = await resp.json();
                user.userData = data.user;
                localStorage.setItem('drp-avatar', data.user.avatar);
                user.avatar = data.user.avatar;
                router.replace(routes.dashboard)
                appState.loading = false;
            } else {
                fullReset();
            }
        })
        .catch(error => {
            console.log(error);
            fullReset();
        })
}

/**
 * Signs the user out from the app and from microsoft
 * - Clears all cookies, storage and user
 * - Logs user out of microsoft
 */
export const signOut = async () => {
    appState.loading = true;
    await fetch(URL.auth.signOut, {
        method: 'GET',
        credentials: 'include'
    })
        .then(() => {
            microsoftSignOutReset();
            appState.loading = false;
            window.location.href = URL.auth.logout;
        })
        .catch(error => {
            console.log(error);
            appState.loading = false;
        })
}

/**
 * Queries the server to checks if the user is signed in
 * 
 * @returns boolean
 */
export const signedInStatus = async () => {
    appState.loading = true;
    let isSignedIn = false;

    await fetch(URL.auth.signedInStatus, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(async (resp) => {
            if (resp.status === statusCode.OK) {
                const data = await resp.json();
                user.userData = data.user;
                if (!user.avatar) {
                    const avatar = await getProfileAvatar();
                    if (avatar) {
                        user.avatar = avatar;
                        localStorage.setItem('drp-avatar', avatar);
                    }
                }
                appState.loading = false;
                isSignedIn = true;
            } else {
                resetUserStore();
                resetAppStore();
                isSignedIn = false;
            }
        })
        .catch(() => {
            appState.loading = false;
            isSignedIn = false;
            resetUserStore();
            resetAppStore();
        })
    return isSignedIn;
}

