<template>
    <div class="auth-container"></div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { user } from '../Data/Store/user';
import { signIn } from '../Data/Services/AuthService';

export default defineComponent({
    beforeMount() {
        signIn();
    },
    data() {
        return user;
    }
})

</script>

<style lang="scss" scoped></style>