export const statusCode = {
    OK: 200, // Success
    BadRequest: 400, // The server could not understand the request due to invalid syntax.
    Unauthorized: 401, // The client must authenticate itself to get the requested response.
    Forbidden: 403, // The client does not have access rights to the content, i.e., authorization will not help.
    NotFound: 404, // The server can't find the requested resource.
    MethodNotAllowed: 405, // The request method is known by the server but is not supported by the target resource.
    NotAcceptable: 406, // The requested resource is only capable of generating content not acceptable according to the Accept headers sent in the request.
    Conflict: 409, // Indicates that the request could not be processed because of conflict in the request, such as an edit conflict between multiple simultaneous updates.
    InternalServerError: 500, // A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
    NotImplemented: 501, // The server either does not recognize the request method or lacks the ability to fulfill the request.
    BadGateway: 502, // The server was acting as a gateway or proxy and received an invalid response from the upstream server.
    ServiceUnavailable: 503, // The server is currently unavailable (because it is overloaded or down for maintenance).
    GatewayTimeout: 504, // The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
    HTTPVersionNotSupported: 505 // The server does not support the HTTP protocol version used in the request.
};