import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "large-text-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "big-text-container"
}
const _hoisted_5 = {
  key: 1,
  class: "big-text-container"
}
const _hoisted_6 = {
  key: 2,
  class: "big-text-container"
}
const _hoisted_7 = { class: "big-text-container" }
const _hoisted_8 = { class: "item-footer" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "info-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.itemIcon,
        width: "15",
        height: "15",
        loading: "lazy"
      }, null, 8, _hoisted_3),
      _createElementVNode("span", null, _toDisplayString(_ctx.selectedWorkItem?.id) + " - " + _toDisplayString(_ctx.selectedWorkItem?.title), 1)
    ]),
    (!_ctx.isBug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Description", -1)),
          _createVNode(_component_TextEditor, {
            onDataChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dataChanged = true)),
            modelValue: _ctx.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
            placeholder: "Click to add a Description"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isBug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Repro Steps", -1)),
          _createVNode(_component_TextEditor, {
            onDataChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dataChanged = true)),
            modelValue: _ctx.reproSteps,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reproSteps) = $event)),
            placeholder: "Click to add Repro Steps"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isBug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[12] || (_cache[12] = _createElementVNode("h3", null, "System Info", -1)),
          _createVNode(_component_TextEditor, {
            onDataChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dataChanged = true)),
            modelValue: _ctx.systemInfo,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.systemInfo) = $event)),
            placeholder: "Click to add System Info"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Acceptance Criteria", -1)),
      _createVNode(_component_TextEditor, {
        onDataChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dataChanged = true)),
        modelValue: _ctx.acceptanceCriteria,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.acceptanceCriteria) = $event)),
        placeholder: "Click to add Acceptance Criteria"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_GenericButton, {
        btnClass: "save-button",
        text: "Save",
        onClick: _ctx.save,
        disabled: !_ctx.interactable
      }, null, 8, ["onClick", "disabled"]),
      _createElementVNode("img", {
        onMouseover: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showInfoBox = true)),
        onMouseout: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showInfoBox = false)),
        src: _ctx.icons.info.info,
        width: "30",
        height: "30"
      }, null, 40, _hoisted_9),
      (_ctx.showInfoBox)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.messages.info.imageUploadInfo), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}