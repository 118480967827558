import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "features-container" }
const _hoisted_2 = { class: "card-item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card-item" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "card-item" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "features-container news-container"
}
const _hoisted_9 = { class: "card-item" }
const _hoisted_10 = { class: "news-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsContent = _resolveComponent("NewsContent")!
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.images.landing.estimates,
          width: "110",
          height: "110",
          loading: "lazy"
        }, null, 8, _hoisted_3),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, [
          _createElementVNode("h5", { class: "card-title" }, "Estimates"),
          _createElementVNode("p", { class: "card-text" }, "Conduct refinement sessions where team members collaboratively evaluate and estimate the complexity of sprint work items through a consensus-based voting process. ")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.images.landing.refinement,
          width: "110",
          height: "110",
          loading: "lazy"
        }, null, 8, _hoisted_5),
        _cache[1] || (_cache[1] = _createElementVNode("div", null, [
          _createElementVNode("h5", { class: "card-title" }, "Sprint Refinement"),
          _createElementVNode("p", { class: "card-text" }, "Facilitate refinement sessions to review tagged work items, engage in visualisation, discussion, and collective voting. Upon completion of the estimation and voting process, seamlessly submit the complexity scores of work items directly to your Azure DevOps project.")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          src: _ctx.images.landing.dashboard,
          width: "110",
          height: "110",
          loading: "lazy"
        }, null, 8, _hoisted_7),
        _cache[2] || (_cache[2] = _createElementVNode("div", null, [
          _createElementVNode("h5", { class: "card-title" }, "Dashboard"),
          _createElementVNode("p", { class: "card-text" }, " Access a dashboard featuring various refinement statistics. Easily monitor different refinement outcomes, analyse work item data, and gain valuable insights of your project. ")
        ], -1))
      ])
    ]),
    (_ctx.update?.content)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, [
              _cache[3] || (_cache[3] = _createElementVNode("h2", null, "News and updates", -1)),
              _createVNode(_component_NewsContent, {
                content: _ctx.update?.content
              }, null, 8, ["content"]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_GenericButton, {
                  text: "All news and updates",
                  onClick: _ctx.newsRedirect
                }, null, 8, ["onClick"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}