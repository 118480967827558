import { reactive } from 'vue';

interface RefinementState {
    session: {
        users: Record<string, any>;
        data: {
            itemSelected: number;
            uniqueVotes: any[];
            revealed: boolean;
            restricted: boolean;
        };
        loaded: boolean;
    };
}

// Create a new store instance.
const initialState: RefinementState = {
    session: {
        users: {},
        data: {
            itemSelected: -1,
            uniqueVotes: [],
            revealed: false,
            restricted: false
        },
        loaded: false
    }
};

export const refinement: any = reactive({ ...initialState })

export const resetRefinementStore = () => {
    Object.assign(refinement, initialState);
}