const devBaseUrl = process.env.VUE_APP_DEV_BASE_URL || '';
const prodBaseUrl = process.env.VUE_APP_PROD_BASE_URL || '';
export const baseUrl = process.env.NODE_ENV === 'production' ? prodBaseUrl : devBaseUrl;

const composeUrl = (endpoint: string) => `${baseUrl}/${endpoint}`;
export const URL = {
    auth: {
        authorize: composeUrl('auth/authorize'),
        signIn: composeUrl('auth/signIn'),
        signOut: composeUrl('auth/signOut'),
        signedInStatus: composeUrl('auth/signedInStatus'),
        logout: 'https://app.vssps.visualstudio.com/_signout'
    },
    profile: {
        get: composeUrl('profile/get'),
        avatar: composeUrl('profile/avatar')
    },
    organizations: {
        get: composeUrl('organizations/get'),
        save: composeUrl('organizations/save')
    },
    projects: {
        get: composeUrl('projects/get'),
        save: composeUrl('projects/save')
    },
    workItems: {
        get: composeUrl('workItems/get'),
        getById: composeUrl('workItems/getById'),
        update: composeUrl('workItems/update')
    },
    sessions: {
        get: composeUrl('sessions/getRefinementSessions'),
        getQueries: composeUrl('sessions/getQueries'),
        save: composeUrl('sessions/createSession'),
        delete: composeUrl('sessions/deleteRefinementSession')
    },
    teams: {
        get: composeUrl('teams/get')
    }
};