<template>
    <button :class="[btnClass, 'generic-button', { 'disabled': disabled }]" @click="handleClick" :disabled="disabled">
        <img v-if="iconSrc" :src="iconSrc" width="20" height="20" loading="lazy" />
        <div class="text" v-if="text">{{ text }}</div>
    </button>
</template>

<script>
export default {
    props: {
        iconSrc: {
            type: String,
            required: false
        },
        btnClass: {
            type: String,
            default: ''
        },
        onClick: {
            type: Function,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClick() {
            this.onClick();
        }
    }
};
</script>

<style lang="scss" scoped>
.generic-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: none;
    border-radius: 5px;
    background: $primary-purple;
    color: $white;
    min-width: 5em;
    padding: 0.3em 0.5em 0.3em 0.5em;
    font-weight: bold;
}

.generic-button:not(:disabled):hover {
    background: $secondary-purple;
}

.disabled {
    background-color: $info-text;
}
</style>